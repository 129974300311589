/* Scss Document */


.section-news {
    margin-top: 50px;
    margin-bottom: 50px;
    @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
       /* margin-top: 50px;
        margin-bottom: 70px;*/
    }
    @media (max-width: $screen-xs-max) {
		padding: 0 15px;
        /*margin-top: 45px;
        margin-bottom: 80px;*/
    }
    h2 {
        margin-bottom: 50px;
         @media (max-width: $screen-xs-max) {
            text-align: center;
            margin-bottom: 36px;
         }
    }
    .nav-tabs {
        display: inline-block;
        border: $accent-gray;
        /*margin-right: 90px;*/
        margin-bottom: 65px;
        float: left;
        @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
            margin-right: 30px;
        }
        @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
            margin-bottom: 25px;
            margin-right: 0;
            width: 100%;
        }
         @media (max-width: $screen-xs-max) {
            width: 100%;
            margin-bottom: 20px;
			display: flex;
			flex-wrap: wrap;
         }
        //width: 550px;
        li {
            border: 1px solid $pearl-gray;
            background: $light-gray;/*rgba(244, 242, 242, 0.71)*/
			&.active {
				 background:$white;
			}
            @media (max-width: $screen-sm-max) {
                width: 33%;
				display: flex;
				flex-direction: column;
				justify-content:center;
            }
            a {
                padding: 21px 35px 20px 35px;
                color: $jet-black;
                font-size: 2rem; /*25px*/
                line-height: 25px;
                font-weight: 400;
                border-radius: 0;
                margin:0;
                border:0;
                @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    font-size: 1.5rem;
                    line-height: 20px;
                    padding: 15px 20px;
                }
				 @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
					 
				}
                 @media (max-width: $screen-xs-max) {
                    font-size: 14px;
                    line-height: 14px;
                    padding:15px 0;
                    text-align: center;
                 }
                  @media (max-width: 350px) {
                    font-size: 12px;
                    line-height: 12px;
                  }
            }
            &:hover {
                background:$white;
                a {
                    color: $ocean-blue; /*#0d5cab*/
                    background:transparent;
                }
            }
            &.active {
                border-bottom-color:$white;
                a{
                    border: 0;
                    font-weight: 500;
                    color: $ocean-blue;
                }
            }
        }//li
    }//.nav-tabs
    .search {
        /*display: inline-block;*/
		display:block;
		padding-left: 45px;
        @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
            /* float: left;
           margin-right: 30px;*/
            margin-bottom: 45px;
			padding-left: 0px;
        }
        @media (max-width: $screen-xs-max) {
            /*width: 45%;*/
			 margin-bottom: 45px;
        }

        @media (max-width: 350px) {
           /* float: left;*/
        }
		@media (max-width: $screen-md-max) {
		padding-left:0px;	
		}
        
        form {
            height: 68px;
            line-height: 68px;
            @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                height: 63px;
                line-height: 63px;
            }
            @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                height: 54px;
                line-height: 54px;
            }
            @media (max-width: $screen-xs-max) {
                height: 43px;
                line-height: 43px;
            }
            input {
                padding-left:35px;
                font-size: 2rem ; /*20px*/
                color:$navy-blue; /*#032547*/
                opacity:1;
                background: $light-gray; /*#f4f2f2*/
               /* width: 188px; */
				width:80%;

                @media(min-width: $screen-lg-min) {
                    height: 68px;
                }
                @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    padding-left: 25px;
                    width: 160px;
					font-size: 1.5rem ;
                }
                @media (max-width: $screen-xs-max) {
                    width: 75%;
                    padding-left: 15px;
                    font-size: 14px;
                }
                &:-webkit-input-placeholder {
                  color: rgba(3, 37, 71, 1);
                  @include opacity(1);
                }
                &:-moz-placeholder { 
                  color: rgba(3, 37, 71, 1);
                 @include opacity(1);
                }
                &:-ms-input-placeholder { 
                  color: rgba(3, 37, 71, 1);
                  @include opacity(1);
                }
                &:-moz-placeholder { 
                  color: rgba(3, 37, 71, 1);
                  @include opacity(1);
                }
            }
            button[type="submit"] {
                background: $yellow;
				width:20%;
                /*  width: 69px;
              	float: right;*/
                @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    width: 63px;
                }
                 @media (max-width: $screen-xs-max) {
                    width: 25%;
                 }
                i {
                    color: $navy-blue;
                    font-size: 22px;
                    position: relative;
                    top: 2px;
                     @media (max-width: $screen-xs-max) {
                        font-size: 17px;
                     }
                }
            }
        }//form
    }//search
    .select_category {
        display: inline-block;
        float: right;
        @media(min-width: $screen-lg-min) {
            max-width: 253px;
        }
        @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
            float: none;
        }
        @media (max-width: $screen-xs-max) {
            margin-bottom: 41px;
            width: 45%;
            margin-right: 5px;
        }
        @media (max-width: 350px) {
            float: right;
            //margin-top: 20px;
        }
        .customSelect {
            @media (max-width: $screen-xs-max) {
                width: 100%;
            }
        }
        .select2-choice {
            padding-left: 35px;
            font-size: 20px;
            color: $navy-blue; /*#032547*/
            background: $light-gray; /*#f4f2f2*/
            width: 260px;
            border: 0;
            height: 68px;
            line-height: 68px;
            position: relative;
            border-radius:0;
            @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                padding-left: 25px;
                width: 210px;
                height: 63px;
                line-height: 63px;
            }
            @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                height: 54px;
                line-height: 54px;
            }
            @media (max-width: $screen-xs-max) {
                width: 100%;
                height: 43px;
                line-height: 43px;
                padding-left: 15px;
                font-size: 14px;
            }
            .select2-arrow {
                background: #f7d31b url(../images/icons/arrow-down.png) center 55% no-repeat;
                width: 70px;
                @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    width: 63px;
                }
                @media (max-width: $screen-xs-max) {
                    width: 38px;
                    background-size:45%;
                }
                @media(max-width: 350px) {
                    width: 32px;
                }
            }
        }//select2-choice
    }//category
    .article {
        display: flex;
        width: 100%;
        margin-bottom: 55px;
        max-height: 90px;
        @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
            max-height: 75px;
            margin-bottom: 30px;
        }
        @media (max-width: $screen-xs-max) {
            margin-bottom: 38px;
            max-height: 100%;
        }
        span {
            min-width: 92px;
            height: 89px;
            line-height: 89px;
            font-weight: 300;
            color:$white;
            font-size: 49px;
            background: $ocean-blue;
            display: block;
            text-align: center;
            float: left;
            margin-right: 28px;
            @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                width: 75px;
                height: 75px;
                line-height: 75px;
                font-size: 30px;
            }
            @media (max-width: $screen-xs-max) {
                width: 70px;
                height: 70px;
                line-height: 70px;
                font-size: 35px;
                min-width: 70px;
                margin-right: 22px;
            }
        }//span 
        .title {
            align-self: center;
            h3 {
                margin-top: 0;
                margin-bottom: 8px;
                @media (min-width: $screen-lg-min) {
                    max-width: 455px;
                }
                @media (max-width: $screen-xs-max) {
                    margin-top: -7px;
                }
				.far{
					font-size: 2rem;
                    color: $ocean-blue;
				
				}
                a {
                    font-size: 2rem; /*24px*/
                    line-height: 30px;
                    color: $ocean-blue;
                    font-weight: 400;
                    @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                        font-size: 20px;
                    }
                    @media (max-width: $screen-xs-max) {
                        font-size: 19px;
                        line-height: 24px;
                    }
                    &:hover {
                        color: $royal-blue;
                    }
                }
            }//h3
            p {
                font-size: 15px;
                color: $ocean-blue;
                font-weight: 500;
                display: inline-block;
                padding-right: 40px;
                text-transform: uppercase;
                margin:0;
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    font-size: 14px;
                    padding-right: 25px;
                }
                @media (max-width: $screen-xs-max) {
                    font-size: 12px;
                    display: block;
                }
            }
        }
    }//article
    .second-column {
        @media (min-width: $screen-lg-min) {
            padding-left:60px;
        }
    }

    .btn-white {
        margin: 0 auto;
        display: block;
        max-width: 202px
    }
}//section-news


