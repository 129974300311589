/* Scss Document */

.overlay {
    position: fixed;
    right: -705px;
    top: 0;
    z-index: 100;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    width: 595px;
    height: 100%;
    background: $mobile-blue;
    color: $white;
	overflow-y: auto;
    @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
        right:-780px;
        width: 690px;
    }
    @media (max-width: $screen-xs-max) {
        width: 100%;
        background: none;
    }

    &.open {
        right: 0;
        @media(max-width: $screen-sm-max) {
            //right:0;
        }
    }

    .overlay-container {
        position: relative;
        height: 100%;

        @media(max-width: $screen-xs-max) {
            background: $mobile-blue;
            /*width: 302px;*/
            float: left;
        }

        @media(max-width: 360px) {
            width: 100%;
        }
    }

    .box-wrap {
        height: 100%;
        padding: 50px 62px 30px 75px;
        @media(max-width: $screen-sm-max) {
            padding-top: 20px;
        }

        @media(max-width: $screen-xs-max) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .close-mobile2 {
        background: url(/_/images/close-menu.png) no-repeat;
        background-size: 40%;
        position: absolute;
        left: -60px;
        height: 63px;
        width: 60px;
        background-color: $mobile-blue;
        background-position: center center;
        top: 0;
        margin: 0;
    }

    .close-container {
        width: 118px; height: 136px;
        background: $mobile-blue;
        position: absolute;
        /*left: -80px; */
		right:0;
		top: 0;
        line-height: 78px;
        @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
            height:95px;
        }

        @media(max-width: $screen-xs-max) {
            height: 74px; width: 74px;
            right: 0;
            left: auto;
            /*background: $white;*/
        }

        a {
            background: url(/_/images/close-menu.png) no-repeat;
            width: 34px; height: 34px;
            display: block;
            margin: 65px 0 0 28px;

            @media(max-width: $screen-sm-max) {
                margin-top: 33px;
            }
            @media(max-width: $screen-xs-max) {
                margin-top: 29px;
                margin-left: 25px;
                width: 24px; height: 24px;
                background: url(/_/images/close-menu.png) no-repeat;
                background-size: 100%;
            }
        }
    }

    a {
        color: $white;
    }

    .search-form {
        height: 64px;
        width: 100%;
        margin-bottom: 62px;
        @media(max-width: $screen-xs-max) {
            height: 45px;
        }
        .input-group {
            border-bottom: 1px solid #89a7c1;
            /*padding-bottom: 8px;*/
				.input-group-btn{
					font-size: inherit!important;
					}
        }
        .inp {
            width: 100%;
            height: 64px;
            border: 0;
            padding: 0 15px;
            font-size: 20px;
            font-size: 2rem;
            color: $white;
            background: $mobile-blue;

            @media(max-width: $screen-xs-max) {
                height: 45px;
            }

            &::-webkit-input-placeholder {
              @include opacity(1);
              color: $white;
            }
            &::-moz-placeholder {
              @include opacity(1);
              color: $white;
            }
            &:-ms-input-placeholder {
              @include opacity(1);
              color: $white;
            }
            &:-moz-placeholder {
              @include opacity(1);
              color: $white;
            }
        }

        button {
            /*width: 70px; */
            height: 64px;
            background: transparent;
            color: $white;
            font-size: 40px;
            font-size: 4.0rem;
            @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                font-size: 30px;
                font-size: 3.0rem;
            }
            @media(max-width: $screen-xs-max) {
                height: 30px; width: 30px;
                font-size: 30px;
                line-height: 30px;
            }
        }
    }

    .mobile-menu {
        margin-bottom: 61px;
        @media(max-width: $screen-xs-max) {
            margin-bottom: 30px;
            margin-left:23px
        }

        ul {
            li {
                font-weight: 400;
                /*font-size: 38px;*/
                font-size: 3rem;
                margin-bottom: 17px;
                @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                   /* font-size: 32px;
                    font-size: 3.2rem;*/
                    margin-bottom: 5px;
                }
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    /*font-size: 28px;*/
                    font-size: 2.8rem;
                    margin-bottom: 5px;
                }
                @media(max-width: $screen-xs-max) {
                   /* font-size:29px;*/
                    font-size: 2.5rem;
                    margin-bottom: 5px;
                }
                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }//mobile-menu

    .mobile-menu-small {
        @media(max-width: $screen-xs-max) {
            margin-left: 23px;
        }
        ul {
            display: inline-block;
            padding-right: 48px;
            &:nth-of-type(2) {
                padding-right: 0;
            }
            li {
                margin-bottom: 5px;
                a {
                    font-size: 21px;
                    font-weight: 400;
                    color:#fff;
                    text-decoration: underline;
                    @media (max-width: $screen-xs-max) {
                        font-size:14px;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }//mobile-menu-small

    .box-social {
        //max-width: 340px;
        border-top: 1px solid rgba(120, 154, 185, 0.41);
        margin-bottom: 50px;
        margin-top: 45px;
        display: inline-block;

        @media(max-width: $screen-xs-max) {
            margin-bottom: 30px;
        }

        ul {
            margin: 35px 0 0 0;
            padding: 24px 0;
            li {
                float: left;
                font-size: 36px;
                font-size: 3.6rem;
                line-height: 41px;
                margin-right: 44px;
                &:last-child {
                    margin-right: 0;
                }
                @media(max-width: $screen-xs-max) {
                    font-size: 26px;
                    font-size: 2.6rem;
                    margin-right: 35px;
                }
                a {
                    color: $white;
                    @media(max-width: $screen-xs-max) {
                        font-size: 35px;
                    }
                    @media (min-width: $screen-sm-min) {
                        .fa-facebook {
                            font-size: 43px;
                            margin-top:1px;
                        }
                    }
                    &:hover {
                        @include opacity(0.7);
                    }
                }
            }
        }
    }
}//mobile menu

