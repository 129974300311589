/* Scss Document */

.section-most-requested {
    margin-top: 50px;
    margin-bottom: 50px;
    @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
       // padding:50px 0;
        
    }
    @media (max-width: $screen-xs-max) {
            //padding-top: 30px;
    }
    h2 {
        text-align: center;
        margin-bottom: 50px;
        @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
           /* margin-bottom: 40px;*/
        }
        @media (max-width: $screen-xs-max) {
           /*max-width: 300px;
           margin:0 auto 40px auto;*/
        }
    }//h2
    .carousel, .carousel-xs {
        position: relative;
        @media (max-width: $screen-xs-max) {
            padding:0 15px;
        }
        .carousel-item {
            a {
                img {
                    margin: 0 auto 27px auto;
                    @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                        width: 90%;
                    }
                    @media (max-width: $screen-xs-max) {
                        margin-bottom:20px;
                    }
                }
                p {
                    text-align: center;
                    color: $ocean-blue;
                    font-size:22px;
                    line-height: 22px;
                    display: block;
                    @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                        font-size: 18px;
                        line-height: 18px;
                    }
                    @media (max-width: $screen-xs-max) {
                        margin-bottom: 20px;
                        font-weight: 400;
                    }
                    &:hover {
                        color: $ocean-blue;
                    }
                }
            }//a
        }//carousel-item
        .btn-primary {
            @media (max-width: $screen-xs-max) {
                margin-top: 47px;
                height: 61px;
                line-height: 58px;
            }
        }
        .slick-arrow {
            &.slick-next {
                right: -53px;
                @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    right:-10px;
                }
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    right:0;
                }
            }
            &.slick-prev {
                left:-52px;
                @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    left:-10px;
                }
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    left:0;
                }
            }
        }
    }//carousel
}//section-most-requested

