/* Scss Document */


.top_content {
    margin-top: 70px;
    margin-bottom: 92px;
    @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
        margin-top: 50px;
        margin-bottom: 70px;
    }
    @media (max-width: $screen-xs-max) {
        padding:0 15px;
        margin-top: 60px;
        margin-bottom: 60px;
    }
    h2 {
        margin-bottom: 40px;
    }
    .carousel {
		.slick-slide img {
			/*width:100%!important;*/
		}
		.slick-track{
			display:flex;
			align-items:stretch;
			justify-content:center;
		}
        .carousel-item {
            /*margin:0 15px;*/
            @media (max-width: $screen-sm-max){
                img {
                    margin: 0 auto;
                }
            }
          //  min-height: 435px;
           
           .box-title {
            border: 1px solid $pearl-gray;
            border-top:0;
            //min-height: 226px;
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 25px;
            @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                padding: 0 15px 15px 15px;
            }
            @media (max-width: $screen-sm-max) {
                /*max-width: 264px;*/
                margin: 0 auto;
                padding-bottom:20px;
            }
            @media (max-width: $screen-xs-max) {
                padding-left: 15px;
                padding-right: 15px;
            }
                h3 {
                    margin-top:0;
                    padding-top: 26px;
                    display: inline-block;
                    margin-bottom: 15px;
                    @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                        padding-top: 30px;
                    }
                    @media (max-width: $screen-sm-max) and(min-width:$screen-sm-min) {
                        padding-top: 20px;
                        margin-bottom: 5px;
                    }
                    @media (max-width: $screen-xs-max) {
                        padding-top: 15px;
                        margin-bottom: 15px;
                    }
                    a {
                        color: $ocean-blue;
                        font-size: 23px;
                        line-height: 30px;
                        text-decoration: underline;
                        min-height: 58px;
                        display: flex;
                        align-items: center;
                        @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                            min-height: 50px;
                            font-size: 18px;
                            line-height: 25px;
                        }
                        @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                            font-size: 20px;
                            line-height: 25px;
                        }
                        @media (max-width: $screen-xs-max) {
                            font-size: 18px;
                            line-height: 22px;
                            min-height: auto;
                        }
                        &:hover {
                            color:$royal-blue;
                            text-decoration: none;
                        }
                    }
                }//h3
                p {
                    font-size:16px;
                    color:$jet-black;
                    @media (max-width: $screen-md-max) {
                        font-size: 15px;
                    }
                }
            }//title
        }//carousel-item
        .slick-arrow{
            opacity:0.3;
            margin-top:-53px;
            @media(max-width: $screen-xs-max) {
                opacity: 0.8;
                background-color:$white;
            }
            &.slick-next {
                right: -41px;
                @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    right:-27px;
                }
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    right: 0;
                }
                @media (max-width: $screen-xs-max) {
                    right: -10px;
                }
            }
            &.slick-prev {
                left:-41px;
                @media(max-width: $screen-md-max) and (min-width: $screen-md-min){
                    left: -27px;
                }
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    left:0;
                }
                @media (max-width: $screen-xs-max){
                    left: -10px;
                }
            }
        }
    }//carousel
}//top_content


