/* Scss Document */

 .breadcrumb {
        background: $light-gray; 
        min-height: 50px;
        line-height: 50px;
      
        @media (max-width: $screen-xs-max) {
        
            padding: 0 15px;
            line-height: 30px;
        }
		 a {
		color:$dark-blue; 
		font-size:15px;
		line-height: 15px;
		padding: 0 5px;
		text-decoration: underline;
		color: $jet-black; 
			&:hover {
			color:$dark-blue; 
			}
			@media (max-width: $screen-xs-max) {
			font-size: 12px;
			line-height: 12px;
			&:nth-of-type(1) {
			padding-left: 0;
			}
			&:last-child {
			display: block;
			padding-left: 0;
			}
											}
		}
        span {
            color:$dark-blue; 
            font-size:15px;
            line-height: 15px;
            padding: 0 5px;
            @media (max-width: $screen-xs-max) {
                font-size: 12px;
                line-height: 12px;
                &:nth-of-type(1) {
                    padding-left: 0;
                }
                &:last-child {
                    display: block;
                    padding-left: 0;
                }
            }
            a {
                text-decoration: underline;
                color: $jet-black; /*#000*/
                &:hover {
                    color:$dark-blue; 
                }
            }
        }
    }//breadcrumb

