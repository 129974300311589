
ul{
	&.sidebar-widget{
	padding-left: 0;
	list-style: none;
	padding-right: 70px;
		
	li{
		padding: 18px 0 18px 9px;
		border-left: none!important;
		border-right: none!important;
		font-weight: 500;
		position: relative;
		display: block;
		background-color: $white;
		border-bottom: 1px solid #ddd;
			&:first-child {
			border-top: none;
			border-radius: 0;
			}
		a{
			text-decoration: none;
			&:hover{
			color: $emerald-green-compliant;	
			text-decoration: underline!important;
			}
			&.navHighlight{
			color: $emerald-green-compliant;
			}
			
		}
		.navHighlight{
		background-color: $emerald-green-compliant;
			a{
				color:$white;
			}
		}
		}/*list group item*/		
	}

	}/*ul*/
	
	

