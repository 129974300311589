// fonts
$font: 'Work Sans', sans-serif;

// colors 
$bgcolor: #fff;

$white:#fff;
$snow-white:#c8caca;
$jet-black:#000; //body
$onyx-black:#070000;
$black-olive:#010000; //section text
$dark-gray:#333;
$light-gray:#f2f6f8;
$pearl-gray:#ebebeb;//separator
$muted-gray:#e2e0e0;
$steel-gray:#aaa;
$accent-gray:#e4e4e4;
$section-gray:#f8fafb;
$input-text: #8b8e91; //input
$ocean-blue:#0b57a2; //btn
$navy-blue:#011d38;
$denim-blue:#101a67;
$royal-blue:#033a71; //footer
$sky-blue:#2c8de4; //sidebar link
$azure-blue:#075af2;
$dark-blue:#262966;
$mobile-blue:#02427b; //mobile menu
$yellow: #f7d31b;//btn
$sun-yellow: #fed141;
$emerald-green-compliant: #006739;
$emerald-green:#00a950;//green btn
$lime-green:#02da68;//green btn hover
$list-box-bg:#f7f7f7;
$list-box-heading-green:#477d5b;
$list-box-heading-magento:#7f5d3a;
$list-box-heading-brown:#9b3556;
$list-box-heading-indigo:#07325c;
$list-box-heading-blue:#1f86ee;




$screen-xlg: 1435px;
$screen-lg-max: 1434px;
// radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin transition($type: all, $duration:0.2s, $ease:ease-out) {
  -webkit-transition: $type $duration $ease;
  -moz-transition: $type $duration $ease;
  -o-transition: $type $duration $ease;
  transition: $type $duration $ease;
}

// Opacity
@mixin opacity($value){
  $IEValue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
  filter: alpha(opacity=$IEValue);
}

// useful
.nolist {
	list-style: none;
}

.clearBoth {
	clear: both;
}