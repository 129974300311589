/* Scss Document */

.section-text {
    background: $light-gray; /*#f8fafb*/
    .picture {
        width: 48%;
        float: left;
        @media (max-width: $screen-sm-max) {
            width: 100%;
        }
        img {
           height: 491px;
           @media (min-width: $screen-md-min) {
            object-fit: cover;
           }
           @media (max-width: $screen-sm-max) {
            height: auto;
           }
           @media (max-width: $screen-xs-max)  {
            margin:0 auto;
           }
        }
    }//picture
    .icons {
        @media (max-width: $screen-xs-max) {
            img {
                margin:-65px auto 0 auto;
            }
        }
    }
    .text-wrapper {
        width: 50%;
        float: right;
        padding-top: 94px;
        padding-left: 78px;
        @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
            padding-left: 30px;
        }
        @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
            width: 100%;
            float: none;
            padding-left:0;
            clear:both;
            padding: 40px 20px;
            
        }
        @media (max-width: $screen-xs-max)  {
            width: 100%;
            padding: 35px 30px 60px 30px;
            text-align: center;
        }
        h2 {
            margin-bottom: 35px;
             @media (max-width: $screen-xs-max) {
                text-align: center;
                margin-bottom: 27px;
             }
        }
        p {
            font-size: 18px;
            color: $black-olive;
            margin-bottom: 50px;
            @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                font-size: 16px;
                margin-bottom: 35px;
            }
            @media (max-width: $screen-sm-max) {
                br {
                    display: none;
                }
            }
            @media (max-width: $screen-xs-max) {
                font-size: 15px;
                text-align: left;
                line-height: 25px;
            }

        }
        .btn-primary {
            font-size: 19px;
        }
    }//text-wrapper
    &.subpage {
        .btn-primary{
            padding: 0;
            width: 195px;
        }
    }
}//section-text

