/* Hero Scss Document */

.section-hero {
    .slick-arrow {
        position: absolute;
        top: 50%;
        background: url(/_/images/icons/arrow-hero.png) no-repeat;
        opacity:0.8;
        background-size: cover;
        width: 53px; height: 58px;
        margin-top: -29px;
        text-indent: -10000px;
        z-index: 2;
        &:hover {
            opacity:1;
        }
        @media (max-width: $screen-xs-max) {
            /*top: auto;*/
            background-size: 92%;
            bottom: 0;
            /*margin-top: 0;
            margin-bottom: 0;*/
            &.slick-prev {
                /*margin-bottom: -5px;*/
            }
        }
        &.slick-next {
            transform: rotate(180deg);
        }
    }
}//section-hero 

.section-hero {
    position: relative;
	&.sectional{
	height:435px;	
	}
	.slick-slide{
		img{
			display: unset;
		}
	}
        .slider-wrapper {
            position: absolute;
            top: 0;
			left:0;
			right:0;
			/* 
			display: flex;
			align-items: center;
			margin:0 auto;*/
            z-index: 2;
            height: 100%;
			@media(min-width: $screen-sm-min){
			display: flex;
			align-items: center;	
			}

            @media (min-width:$screen-lg-min) {
                .text-center {
                    padding-right:0;
                }
            }
            @media (max-width: $screen-xs-max) {
                /*display: block;*/
            }
            .mask {
                @media(min-width: $screen-sm-min) {
                    display: none;
                }
                @media(max-width: $screen-xs-max) {
                    position: absolute;
                    top:0;
                    left:0;
                    background:rgba(0, 0, 0, 0.55);
                    width: 100%;
                    height: 100%;
                }
            }
            .txt-hero {
                background:rgba(0, 0, 0, 0.7);
               /* padding: 57px 65px 0 65px;*/
                max-width: 843px;
                min-height: 265px;
                margin: 0 auto;
				
                @media (min-width: $screen-sm-min) {
                    min-height: auto;
					padding: 30px;
                }
                @media (max-width: $screen-xs-max) {
                    background: none;
					margin-top:40%;
                    /*padding: 70px 30px 0 30px;*/
                }
                &.subpage {
                    @media (min-width: $screen-sm-min) {
                        background:rgba(0, 0, 0, 0.6);
                    }
                    p {
                        @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                            br {
                                display: block;
                            }
                        }
                    }
                }
                h1 {
                    margin-top:0;
                    margin-bottom: 13px;
                    color:$white;
                    font-size:50px;
                    font-weight: 400;
                    @media (max-width: $screen-sm-max) {
                        font-size: 40px;
                    }
                    @media (max-width: $screen-xs-max) {
                        margin:0 auto 40px auto;
                        max-width: 285px;
                    }
                }
                p {
                    color: $white;
                    font-size: 17px;
                    font-weight: 400;
                    @media (max-width: $screen-md-max) {
                        br {
                            display: none;
                        }
                    }
                    @media (max-width: $screen-xs-max) {
                        font-size:15px;
                        line-height: 24px;
                        width: 259px;
                        margin: 0 auto;
                    }
                }
            }//txt-hero
        }//slider-wrapper
    //}//carousel-slider
    .slick-arrow {
        &.slick-next {
            right:9px;
            @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                right: -38px;
            }
            @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                right: 0;
            }
            @media (max-width: $screen-xs-max) {
                right: 0;
            }
        }
        &.slick-prev {
            left:24px;
            @media (max-width: 1500px) and (min-width: $screen-lg-min) {
                left:25px;
            }
            @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                left: -38px;
            }
            @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                left: 0;
            }
            @media (max-width: $screen-xs-max) {
                left: 0;
            }
        }
    }//slick-arrow
    .btn-primary {
        margin-top: -38px;
        @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min)  {
            margin-top: -20px;
        }
        @media (max-width: $screen-xs-max) {
            margin-top: 47px;
        }
    }
}//section-hero

