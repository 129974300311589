/* Scss Document */


.leisure-services {
   
    h2 {
        @media (max-width: $screen-xs-max) {
            padding:0 15px;
        }
    }
    .pic {
        padding: 0;
        margin-top: 63px;
        @media (max-width: $screen-xs-max) {
            margin-top: 30px;
            text-align: center;
        }
        img {
            border: 6px solid $muted-gray; /*#e1e1e1*/
            border-radius:100%;
        }
    }//pic
    .text-column {
       /* margin-top: 60px;*/
        padding-left: 48px;
        @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
            clear: both;
            padding-left: 15px;
            margin-top:0;
        }
        @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min){
            padding-left: 15px;
        }
        @media (max-width: $screen-xs-max) {
            padding-left: 15px;
        }
        span {
            color: $jet-black;
            font-size: 25px;
            font-weight: 400;
            padding-bottom: 34px;
            display: inline-block;
            border-bottom: 1px solid $pearl-gray;
            @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
               font-size:22px;
            }
            @media (max-width: $screen-xs-max) {
                font-size: 20px;
                padding-bottom: 25px;
            }
            @media (max-width: $screen-sm-max) {
                br {
                    display: none;
                }
            }
        }
        p {
            font-size: 18px;
            color:$jet-black;
            margin-top: 35px;
            margin-bottom: 60px;
            line-height: 29px;
            @media (max-width: $screen-sm-max) {
                br {
                    display: none;
                }
            }
            @media (max-width: $screen-xs-max) {
                font-size: 15px;
                margin-top: 30px;
                margin-bottom: 35px;
                line-height: 25px;
            }
        }
    }//text-column
    .sidebar {
        /*margin-top: -60px;*/
        @media (max-width: $screen-xs-max) {
            margin-top: 0;
        }
        h3 {
            color:$royal-blue;
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 42px;
            @media (max-width: $screen-sm-max) {
                font-size: 25px;
                margin-bottom: 30px;
            }
        }
        span {
            color:$jet-black; /*#030000*/
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 25px;
            display: block;
            border-top: 1px solid $pearl-gray; /*#ededed*/
            padding-top: 27px;
			&:first-child{
				 border-top: none;;
			}
        }
        .contact-box {
            border-top: 1px solid $pearl-gray; /*#ededed*/
            padding: 20px 0 24px 0;
            display: flex;
            align-items: center;
            @media (max-width: $screen-xs-max) {
                padding-left: 15px;
            }
            img {
                display:inline-block;
            }
            p {
                display: inline-block;
                padding-left: 30px;
                margin-bottom: 0;
                font-size: 14px;
                color: $jet-black; /*#012d59*/
                @media (max-width: $screen-xs-max) {
                    font-size: 15px;
                    padding-left: 25px;
                }
            }
            a {
                display: inline-block;
                padding-left: 30px;
                margin-bottom: 0;
                font-size: 14px;
                color: $dark-blue;
                @media (max-width: $screen-xs-max) {
                    padding-left: 28px;
                    font-size: 15px;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
            &.address {
                p {
                    color:$dark-blue;
                  
                }
            }
        }//contact-box
    }//sidebar
    hr {
        border-bottom: 0px solid $pearl-gray; /*#e1e1e1*/
        width: 100%;
        clear: both;
        margin: 0;
    }
}//leisure-services

