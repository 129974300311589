.mainContent{
	.listBox {
		margin: 15px 0;
		&.u-bg1 {
		background-color:$list-box-heading-indigo;
		}
		&.u-bg2 {
		background-color:$list-box-heading-blue;
		}
		&.u-bg3{
		background: $list-box-heading-green;
		}
		&.u-bg4 {
    	background-color:$list-box-heading-magento;
		}
		&.u-bg5{
		background-color:$list-box-heading-brown;	
		}
		
		.inner {
		padding: 0px 22px;
		}
		h3{
		letter-spacing: 1px;
		font-size: 1.563rem;
		line-height: 26px;
		color: $white;
		padding: 21px 22px;
		padding-right: 40px;
		margin: 0;
		font-style: normal;
		font-weight: 400;
		background-color:$emerald-green-compliant;
		}
		ul{
		 margin-top: 10px;
			list-style: none;
			li{
			background: transparent url(/core/fileparse.php/10/urlt/arrow-list1.png) no-repeat left 2px;
			padding: 0 0 7px 13px;
			font-size: 14px;
			line-height: 18px;
			}
		}
	}
	ul{
		list-style-position: inside;
		li{
			padding-left:22px;
		}
	}/*ul*/
	.twoColList{
		@media (min-width: 768px) { 
		-webkit-column-count: 2;
		-moz-column-count: 2;
		-ms-column-count: 2;
		column-count: 2;
		}
	}
}
 .sidebar {

        @media (max-width: $screen-xs-max) {
            margin-top: 0;
        }
        h3 {
            color:$royal-blue;
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 42px;
            @media (max-width: $screen-sm-max) {
                font-size: 25px;
                margin-bottom: 30px;
            }
        }
        span {
            color:$jet-black; 
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 25px;
            display: block;
            border-top: 1px solid $pearl-gray; 
            padding-top: 27px;
			&:first-child{
				 border-top: none;;
			}
        }
        .contact-box {
            border-top: 1px solid $pearl-gray; 
            padding: 20px 0 24px 0;
            display: flex;
            align-items: center;
            @media (max-width: $screen-xs-max) {
                padding-left: 15px;
            }
            img {
                display:inline-block;
            }
            p {
                display: inline-block;
                padding-left: 30px;
                margin-bottom: 0;
                font-size: 14px;
                color: $jet-black;
                @media (max-width: $screen-xs-max) {
                    font-size: 15px;
                    padding-left: 25px;
                }
            }
            a {
                display: inline-block;
                padding-left: 30px;
                margin-bottom: 0;
                font-size: 14px;
                color: $dark-blue;
                @media (max-width: $screen-xs-max) {
                    padding-left: 28px;
                    font-size: 15px;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
            &.address {
                p {
                    color:$dark-blue;
                  
                }
            }
        }//contact-box
    }//sidebar
