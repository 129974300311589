/* Nav Scss Document */



header{
&.top {
    padding-bottom: 5px;
    @media(max-width: $screen-sm-max) and (min-width:$screen-sm-min) {
        padding-bottom: 9px;
    }
    @media(max-width: $screen-xs-max) {
        // padding-top: 27px;
        // padding-bottom: 21px;
        padding-top: 15px;
        padding-bottom: 9px;
    }

    .topbar-blue {
        background: $ocean-blue;
        text-align: right;
        min-height: 40px;
        line-height: 38px;
        @media(max-width: $screen-xs-max) {
            display: none;
        }
        ul {
            margin: 0;
            display: inline-block;
            li {
                display: inline-block;
                position:relative;
                padding-right:24px;
                padding-left:12px;
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min)  {
                    padding-right: 20px;
                }
                &:after {
                    content:'/';
                    position: absolute;
                    right:0; top:0;
                    color: rgba(255,255,255,0.5);
                }
                a {
                    color:$white;
                    font-size: 14px;
                    &:hover {
                        color: rgba(255,255,255,0.5);
                    }
                }//a
            }//li
        }//ul
        .change-font {
            display: inline-block;
            margin-left: 7px;
            a {
                color:$white;
                padding-left: 0;
                &.font-size-1 {
                    font-size: 12px;
                }
                &.font-size-2 {
                    font-size:16px;
                }
                &.font-size-3 {
                    font-size:21px;
                }
                &:hover {
                    color: rgba(255,255,255,0.5);
                }
            }//a
        }//change-font
    }//topbar-blue

    .logo {
        margin-top: 5px;
        @media(min-width: $screen-lg-min) {
            padding:0;
        }
        @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min)  {
            margin-top: 9px;
        }
        @media(max-width: $screen-xs-max) {
            margin-top: 0;
            img {
                max-height: 50px;
            }
        }
        @media(max-width: 350px) {
            img {
                height: 45px;
                max-height: 45px;
                object-fit: cover;
            }
        }
    }

  /*  .header-content {
        margin-top: 25px;
        justify-content: right;
      display: inline-flex;
        @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
            margin-top: 10px;
        }
        @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min)  {
            margin-top: 25px;
        }
        @media (max-width: $screen-xs-max) {
            margin-top: 0;
        }
        @media(max-width: 350px) {
            padding-left: 0;
        }
    }*/

    nav {
        display: inline-block;
    }
    .topmenu {
        margin-bottom: 0;
        li {
			display: inline-block;
			/*
			font-size: 24px;
			*/
			padding: 10px 22px;
			font-size: 1.9rem;
			font-weight: 400;
			&:last-of-type{
			padding-right:0px;	
			}
            @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                font-size: 20px;
                padding: 10px;
            }
            @media (max-width: $screen-sm-max) {
                display: none;
            }
            &.btn-menu {
                /*padding:0 39px 0 0;*/
                @media (max-width: 575.98px) {
                    padding-right: 10px;
                    float: right;
                }
				@media (max-width: 991.98px) {
                    display: inline-block;
					
                }
			@media (min-width: 576px) and (max-width: 991.98px)  { 
				padding:0 39px 0 0;	
				}
                a {
                    background: $emerald-green-compliant;
                    color: $white;
                    font-size: 23px;
                    font-weight: 500;
                    //font-size: 1.4rem;
                    height: 48px;
                    line-height: 46px;
                    text-align: center;
                    position: relative;
                    //top: -4px;
                    padding: 0 25px;
					@media (max-width: 575.98px){
					min-width: 90px;	
					}
					@media (min-width: 576px) { 
					 min-width: 152px;
					}
                    @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                        font-size: 20px;
                        height: 44px;
                        line-height: 42px;
                    }
                    @media (max-width: $screen-xs-max) {
                        /*min-width: auto;*/
                        padding:0 20px;
                        height: 45px;
                        line-height: 45px;
                        font-size: 15px;
                        font-weight: 400;
                    }
                    @media(max-width: 350px) {
                        font-size: 13px;
                    }
                    &:hover {
                        color: $white;
                        background: $lime-green;
                    }
                }
            }
            &.search {
                width: 48px;
                height: 48px;
                line-height: 44px;
                text-align: center;
                background: $pearl-gray;
                padding: 0;
                /*margin-right:25px;*/
				margin-right:39px;
                @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    height: 44px;
                    line-height: 42px;
                }
                @media (max-width: $screen-sm-max) {
                    display: inline-block;
                }
                @media (max-width: $screen-xs-max) {
                    display: none;
                    // margin-right:10px;
                    // width: 45px;
                    // height: 45px;
                    // line-height: 40px;
                    // display: inline-block;
                }
                //@media (max-width:363px) {
                    //display: none;
                //}
            }
            a {
                color: $royal-blue;
                display: block;
                &:hover {
                    color: $ocean-blue;
                }
                // i {
                //     font-size: 19px;
                //      @media (max-width: $screen-xs-max) {
                //         font-size:16px;
                //      }
                // }
                img {
                    display: inline-block;
                }
            }
        }//li
    }//topmenu

    .search-bar {
        display: none;
        position: absolute;
        top: 0px; 
		left: 0;
        width: 100%; 
		height: 100%;
        background: $white;
        text-align: center;
        @media (max-width: $screen-xs-max) {
            top:0;
        }

        form {
            margin-top: 0;
            width: 100%;
			height:100%;
            @media(min-width: $screen-lg-min) {
                width: 100%;
                float: right;
            }

            .search-input {
                width: 480px;
				height:100%;
                font-size: 3.5rem;
                color: $snow-white;
                font-weight: 300;
                @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    width: 300px;
                    /*font-size: 25px;*/
                    font-size: 2rem;
                }
                @media(max-width: $screen-xs-max) {
                    width: 170px;
                    /*font-size: 13px;*/
                    font-size: 1.3rem;

                }

                &:-ms-input-placeholder {
                  @include opacity(1);
                  color: $snow-white;
                }
            }

            button {
                font-size: 29px;
                color: $snow-white;
                background: none;
                border: 0;
               // float: right;
                margin-left: 88px;
                @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    margin-left: 15px;
                }
                @media(max-width: $screen-xs-max) {
                    font-size: 15px;
                    margin-left: 15px;
                }
                &:hover {
                    color: $steel-gray;
                }
            }
        }
    }
	}
} //header top

.menu-hamburger {
	display: inline-block;
    background: $white;		
	@media (min-width: $screen-md-min) { 
	display:none!important; 
	}
    span {
        width: 38px; height: 5px;
        background: $jet-black;
        display: block;
        margin-top: 4px;
        @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
            margin-top: 0;
        }
        @media (max-width: $screen-xs-max) {
            margin-top: 2px;
            width: 30px;
            height: 4px;
            background:$ocean-blue;
        }
        + span {
            margin-top: 7px;
            @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                margin-top: 5px;
            }
            @media (max-width: $screen-xs-max) {
                margin-top: 6px;
            }
        }
      }
}//menu-hamburger


