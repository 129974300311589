/* Scss Document */

.tiles_gray {
    margin-top: 95px;
    @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
        margin-top: 50px;
    }
    @media (max-width: $screen-xs-max) {
        padding: 0 15px;
        margin-top: 60px;
    }
    h2 {
        margin-bottom: 50px;
        @media(max-width: $screen-sm-max) {
            margin-bottom: 40px;
        }
    }
    .link-tiles {
        margin-bottom: 20px;
        background:$light-gray; /*#f2f2f2*/
        height: 137px;
        @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
            height: 100px;
        }
        @media (max-width: $screen-xs-max) {
            height: 90px;
        }
        a {
            display: inline-flex;
            width: 100%;
            height: 100%;
            align-items: center;
            position: relative;
            color:$royal-blue; /*#091f2f*/
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            padding-left: 40px;
            text-transform: uppercase;
            @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                padding-left: 15px;
                font-size: 15px;
                line-height: 18px;
            }
            @media (max-width: $screen-xs-max) {
                font-size: 14px;
                line-height: 18px;
                padding-left: 15px;
            }
            @media (max-width: 350px) {
                font-size: 12px;
                line-height: 14px;
            }
            &:after {
                content:'+';
                //top: 15px;
                top: 42%; right:30px;
                position: absolute;
                font-size: 73px;
                font-weight: 300;
             //   @include transition(all, 0.4s, ease);
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    right: 15px;
                    top: 40px;
                    font-size: 50px;
                    top: 33px;
                }
                @media (max-width: $screen-xs-max) {
                    font-size: 45px;
                    right: 10px;
                    top: 33px;
                }
            }
        }
        &:hover {
            background: $ocean-blue;
            @include transition(all, 0.4s, ease);
            a {
                color:$white;
                &:after {
                    color: $white;
                    @include transition(all, 0.4s, ease);
                }
            }
        }
        &.download {
            a {
                &:after {
                    content:'\f045';
                    font-family: 'FontAwesome';
                    //top: 42px;
                    top: 42%;
                    right: 30px;
                    position: absolute;
                    font-size: 41px;
                    font-weight: 300;
                    @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                        right: 15px;
                        font-size: 30px;
                    }
                    @media (max-width: $screen-xs-max) {
                        right: 10px;
                        font-size: 30px;
                    }
                }
            }
            &:hover {
                a {
                    color:$white;
                }
            }
        }//download
    }//link-tiles
}//tiles_gray

