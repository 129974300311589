/* Scss Document */


.carousel {
    .slick-arrow {
        position: absolute;
        top: 50%;
        background: url(../images/icons/arrow-left.png) center no-repeat;
        border-radius:3px;
        border: 1.5px solid $ocean-blue;
        width: 41px;
        height: 41px;
        text-indent: -10000px;
        z-index: 2;
        margin-top:-21px;
        @media (max-width: $screen-md-max) and (min-width: $screen-sm-min) {
            background-color:$white;
        }
        @media (max-width: $screen-xs-max) {
            height: 50px;
            width: 50px;
            border: 2px solid $ocean-blue;
        }
        &.slick-next {
            transform: rotate(180deg);
        }
    }
}//carousel

