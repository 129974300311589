/* Scss Document */

.footer {
    width: 100%; height: 85px;
    background: $white;
    padding-top: 15px;
    @media (max-width: $screen-xs-max) {
        text-align: center;
        padding-top: 22px;
    }

    .font-size {
        padding: 0;
    }

    .change-font {
        font-size: 0;
        text-align: center;
        margin-top: 9px;
        @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
            text-align: left;
            margin-top: 7px;
        }
        @media (max-width: $screen-xs-max) {
            display: inline-block;
            margin-top: 25px;
            margin-bottom: 28px;
        }
        p {
            float: left;
            font-weight: 700;
            font-size: 13px;
            font-size: 1.3rem;
            margin: 5px 10px 0 0;
            @media(max-width: $screen-lg-max) and (min-width: $screen-lg-min) {
                font-size: 12px;
                font-size: 1.2rem;
                margin-right: 1px;
            }

            @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                font-size: 18px;
                font-size: 1.8rem;
            }
        }

        a.size {
            float: left;
            width: 41px; height: 29px;
            border: 1px solid $light-gray; /*#f3f2f1*/
            border-radius: 5px;
            background: $pearl-gray; /*#f3f2f1*/
            text-align: center;
            line-height: 29px;
            font-weight: 400;
            margin: 0 2.5px;
            color: $dark-blue;

            &.active, &:hover {
                background: $ocean-blue;
                color: $white;
            }

            &.font-size-1 {
                font-size: 12px;
                font-size: 1.2rem;
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    font-size: 17px;
                    font-size: 1.7rem;
                }
            }

            &.font-size-2 {
                font-size: 14px;
                font-size: 1.4rem;
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    font-size: 20px;
                    font-size: 2.0rem;
                }
            }

            &.font-size-3 {
                font-size: 17px;
                font-size: 1.7rem;
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    font-size: 24px;
                    font-size: 2.4rem;
                }
            }
        }
    }
    .language {
        margin-top:12px;
        margin-left: -15px;
        img {
            display: inline-block;
            margin-right: 6px;
        }
        .customSelect {
            .select2-choice {
                padding: 0 17px 0 0;
                border: 0;
                color: $jet-black;
                font-weight: 500;
                font-size: 15px;
                position: relative;
               > .select2-chosen {
                    margin-right:0;
                }
                .select2-arrow {
                    &:after {
                        content:'\f0d7';
                        font-family: 'FontAwesome';
                        top:0; right:0;
                        position: absolute;
                        color: $ocean-blue;
                    }
                }
            }
        }
    }//language
    .totop {
        text-align: right;
        a {
            margin-right: -15px;
            display: inline-block;
        }
        @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
            text-align: right;
            a {
                display: inline-block;
            }
        }
    }
}//footer

