/* Scss Document */

.section-help {
    background: $light-gray; /*#f7f6f6*/
    padding-top: 85px;
    padding-bottom: 75px;
    @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
        padding-top: 50px;
        padding-bottom: 70px;
    }
    @media (max-width: $screen-xs-max) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    h2 {
        color:$royal-blue;
        margin-bottom: 37px;
        @media (min-width: $screen-lg-min) {
            font-size: 35px;
        }
        @media (max-width: $screen-xs-max) {
            margin-bottom: 25px;
        }

    }
    p {
        color:$jet-black;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 42px;
        @media (max-width: $screen-md-max){
            br {
                display: none;
            }
        }
        @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 30px;
        }
        @media (max-width: $screen-xs-max) {
            font-size: 15px;
            line-height: 25px;
        }
        a {
            color:$dark-blue;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}//section-help


