/* Scss Document */

.section_quick_link {
    background: $light-gray;
    padding-top: 50px;
    padding-bottom: 50px;
    @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
        /*padding-top: 50px;
        padding-bottom: 70px;*/
    }
    @media (max-width: $screen-xs-max) {
        /*padding-top: 65px;
        padding-bottom: 62px;*/
    }
    h2 {
        text-align: center;
        margin-bottom: 50px;
    }
    .carousel {
        position: relative;
        .carousel-item {
            a {
                background:$white;
                text-align: center;
                height: 201px;
                //width: 231px;
                display: block;
                padding-top: 52px;
                margin:0 2px;
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    margin:0 10px;
                }
                @media (max-width: $screen-xs-max) {
                    margin: 0 91px;
                }
                img {
                    margin: 0 auto;
                }
                span {
                    font-size:18px;
                    color: $ocean-blue;
                    margin-top: 38px;
                    display: block;
                    @media (max-width: $screen-xs-max) {
                        font-size: 16px;
                        margin-top: 35px;
                    }
                }
                &:hover {
                    span {
                        color: $royal-blue; /*#011d38*/
                    }
                }
            }//a
        }//carousel-item
        .slick-arrow {
            &.slick-next {
                right: -56px;
                @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    right: -15px;
                }
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    right:0;
                }
                @media (max-width: $screen-xs-max) {
                    right: 15px;
                }
            }
            &.slick-prev {
                left: -58px;
                @media(max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    left: -15px;
                }
                @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                    left:0;
                }
                @media (max-width: $screen-xs-max) {
                    left: 15px;
                }
            }
        }
    }//carousel
}//section_quick_link


