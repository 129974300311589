/* Scss Document */

.prefooter {
    background: $navy-blue;
    color: $white;
    padding-top: 44px;
    @media(max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
        padding-top: 20px;
    }
    @media (max-width: $screen-xs-max) {
        padding-top: 53px;
        padding-left: 15px;
        padding-right: 15px;
    }
    a {
        color: $white;
        @media (max-width: $screen-sm-max) {
            /*color:#7cbdfb;*/
        }
        &:hover {
            text-decoration: underline;
        }
        &.mail {
            text-decoration: underline;
            font-size: 15px;
            font-weight: 400;
           /* color:#2ea5e3;*/
            margin-top: 19px;
            display: block;
            @media (max-width: $screen-xs-max) {
                margin-bottom: 15px;
            }
            &:hover {
                text-decoration: none;
            }
        }
    }


    .footer-col {
        &.four-col {
            ul {
                li {
                    a {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
        @media(min-width: $screen-xlg) {
            &.second-col {
                padding-left: 0;
            }
            &.third-col {
                padding-left: 44px;
            }
            &.four-col {
                padding-left:78px;
                ul {
                    li {
                        margin-bottom: 15px;
                        //a {
                            //display: flex;
                            //align-items: center;
                        //}
                    }
                }
            }
            &.last-col {
                padding-left: 28px;
            }
        }
        @media (max-width: $screen-xs-max) {
            border-bottom: 2px solid #183a5b;
        }
        &.first-col {
            @media(min-width: $screen-lg-min) {
                padding-left: 0;
            }
        }

        h4 {
            font-weight: 400;
            font-size: 22px;
            font-size: 2.2rem;
            text-transform: uppercase;
            letter-spacing: -1px;
            margin: 0 0 20px 0;
            @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                margin-top:20px;
                font-size: 18px;
                font-size: 1.8rem;
            }
            @media (max-width: $screen-xs-max) {
                font-size:21px;
                font-size: 2.1rem;
                margin-top: 14px;
                margin-bottom: 16px;
                margin-left:-15px;
            }
            @media (max-width: $screen-sm-max) {
                position: relative;
                &:after {
                    content:'+';
                    position: absolute;
                    top:0; right:-10px; 
                    color:#7cbdfb;
                }
                &:hover {
                    cursor: pointer;
                }
                &.active {
                    &:after {
                    content:'-';
                    position: absolute;
                    top:0; right:-10px; 
                    color:#7cbdfb;
                }
                }
            }
        }

        p {
            font-weight: 400;
            margin-bottom: 8px;
            line-height: 24px;
            font-size: 15px;
            font-size: 1.5rem;
            color: $muted-gray;
            @media (max-width: $screen-sm-max){
                /*color:#7cbdfb;*/
            }
            @media (max-width: $screen-xs-max) {
                font-size:17px;
                font-size: 1.7rem;
            }
        }
        .address {
            @media (max-width: $screen-sm-max) {
                display: none;
            }
        &.open {
            @media (max-width: $screen-sm-max) {
                display: block;
            }
        }
        }
        ul {
            margin: 0 0 46px 0;
            @media (max-width: $screen-sm-max) {
                margin-bottom: 20px;
                display: none;
            }
            li {
                font-size: 15px;
                font-size: 1.5rem;
                font-weight: 300;
                margin-bottom: 5px;
                @media (max-width: $screen-xs-max) {
                    font-size: 17px;
                    font-size: 1.7rem;
                }
                img {
                    display: inline-block;
                    margin-right: 20px;
                }
                i {
                    font-size: 25px;
                    //margin-right: 22px;
                    margin-right: 12px;
                    color:$white;
                    width: 33px;
                    text-align: center;
                    &.fa-facebook {
                       // margin-left:8px;
                        margin-top: 5px;
                    }
                }
                span {
                    &.number {
                        border: 3px solid $white;
                        padding: 0px 4px;
                        margin-right: 12px;
                        border-radius: 5px;

                    }
                }
            }
            &.open {
                @media (max-width: $screen-sm-max) {
                    display: block;
                }
            }
        }

        .btn.btn-primary {
            background: $ocean-blue;
            border-radius: 0;
            font-size: 14px;
            font-size: 1.4rem;
            font-weight: 300;
            height: 39px;
            line-height: 37px;
            margin: -15px 0 20px 0;
        }
    }
    .social {
        margin-top: 52px;
        padding-left:0;
        a {
            font-size:26px;
            color: $white;
            margin: 0 25px;
            @media(max-width: $screen-xs-max) {
                margin: 0 20px;
            }
            @media (max-width: 359px) {
                margin:0 15px;
            }
            &.number {
                border:2px solid $white;
                border-radius: 5px;
                padding: 0 2px;
                @media(max-width: $screen-xs-max) {
                    position: relative;
                    top: -2px;
                }
            }
            i {
                font-size: 32px;
            }
        }
    }//social
    .subfooter {
        border-top: 1px solid rgba(4, 65, 124, 0.36);
        padding-top:43px;
        padding-bottom: 35px;
        @media (max-width: $screen-sm-max) {
            text-align: center;
            .text-right {
                text-align: center;
            }
        }
        @media (max-width: $screen-xs-max) {
            border:0;
            padding-bottom:0;
        }
        p {
            font-size:13px;
            font-weight: 400;
            @media (max-width: $screen-xs-max) {
                font-size:10px;
                margin-bottom: 5px;
            }
            a{
                color:$white;
                @media (max-width: $screen-xs-max)  {
                    text-decoration: underline;
                }
            }
        }
        ul {
            @media (max-width: $screen-xs-max)  {
                margin-bottom: 5px;
            }
            li {
                display: inline-block;
                position:relative;
                padding:0 13px;
                line-height: 10px;
                a{
                    font-size: 13px;
                    font-weight: 400;
                    text-decoration: underline;
                    color:$white;
                    @media (max-width: $screen-xs-max) {
                        font-size: 10px;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
                &:after {
                    content:'';
                    position: absolute;
                    border-right: 1px solid $white;
                    height: 10px;
                    top:0; right:0;
                }
                &:last-child {
                    &:after {
                        content:'';
                        display: none;
                    }
                }
            }
        }
        @media (max-width: $screen-xs-max) {
            .totop {
                a {
                    width: 54px;
                    height: 54px;
                    line-height: 54px;
                    background: $white;
                    text-align: center;
                    display: inline-block;
                    margin-top: 38px;
                    i {
                        color:$ocean-blue;
                        font-size:52px;
                    }
                }
            }
        }
    }//subfooter
}//prefooter

